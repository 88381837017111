import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { RelatedPass } from './RelatedPass'

export const RelatedPosts = (props) => {
  const postType = props.contentType ? props.contentType : 'post'
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          },
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          },
          categoryFilter: allWordpressPost {
            nodes {
              categories {
                wordpress_id
                id
                name
                slug
                id
              }
            }
          }
        }
      `}
      render={data => <RelatedPass {...props} data={data} items={props.posts} />}
    />
  );
}
