import React, { Component } from 'react';
import GatsbyLink from '../../../GatsbyLink';
import './RelatedPass.scss';
import Carousel from '../../../Carousel';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import PostListItem from '../../PostListItem'
import { PostListNavigation } from '../../PostListNavigation'
import { decodeEntities } from '../../../../utils/helpers';
import Heading from '../../../Heading/Heading';

class ThisSlider extends Component {
  render () {
    const { posts, siteMetadata, pathPrefix, showAuthor } = this.props;
    const settings = {
      container: 'related-carousel',
      nav: false,
      mouseDrag: true,
      controls: true,
      loop: false,
      gutter: 20,
      slideBy: 1,
      autoHeight: true,
      controlsContainer: '.related-nav',
      responsive: {
        1600: {
          items: 3,
        },
        1024: {
          items: 3,
        },
        640: {
          items: 2,
        },
        420: {
          items: 1,
        }
      }
    };
    
    return (
      <div className="slider">
        <Carousel settings={settings}>
          {posts && posts.map((post, slide_count) => post.node.slug !== 'gatsby' &&
            <PostListItem key={slide_count} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
          )
        }
        </Carousel>
        <div className="related-nav">
          <button aria-label="Previous" type="button" title="Prev" />
          <button aria-label="Next" type="button" title="Next" />
        </div>
      </div>
    )
  }
}

class ListView extends Component {
  render () {
    const { posts, siteMetadata, pathPrefix, showAuthor } = this.props;
    return (
      <div className="slider list-view">
          {posts && posts.map((post, slide_count) => post.node.slug !== 'gatsby' &&
            <PostListItem
            key={slide_count}
            data={post.node}
            siteMetadata={siteMetadata}
            pathPrefix={pathPrefix}
            showAuthor={showAuthor}
            />
          )
        }
      </div>
    )
  }
}

export const RelatedPass = (props) => {
  const { data, title = 'Related Posts', listView, contentType = 'post', items } = props;
  const { categoryFilter, wordpressWpSettings, siteSettings } = data;
  const { showAuthor } = siteSettings.options
  const pathPrefix = contentType === 'project' ? 'project-gallery' : wordpressWpSettings.blogSlug;
  if (!items || items.length === 0) return null;
  return (
    <section className={`post-feed ${contentType}-list`}>
      {listView && <PostListNavigation pathPrefix={wordpressWpSettings.blogSlug} categoryFilter={categoryFilter} />}
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            {!listView && title && <Heading tag="h3" className="title section-title">{decodeEntities(title)}</Heading>}
            {!listView && <ThisSlider posts={items} siteMetadata={wordpressWpSettings} pathPrefix={pathPrefix} showAuthor={showAuthor} />}
            {listView && <ListView posts={items} siteMetadata={wordpressWpSettings} pathPrefix={pathPrefix} showAuthor={showAuthor} />}
          </div>
        </div>
      </div>
    </section>

  )
}
