import React from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { RelatedPosts } from '../components/Posts/Related/RelatedPosts';
import { RenderShortcode } from '../components/RenderShortcode';
import RenderContent from '../components/RenderContent'
import { Image } from '../components/Image';
import AddToQuote from '../components/Quote/AddToQuote'
import { Breadcrumbs } from '../components/Breadcrumbs';
import './post.scss';
import { decodeEntities } from '../utils/helpers';

export const ProductPostTemplate = ({ post, location }) => {
  const { wordpress_id, title, content, date, categories, acf } = post
  return (
    <section className="single-product">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="wrap">
              <Breadcrumbs location={location} />
              <h1>{title}</h1>
              <RenderShortcode content={content} />
              {acf.productDetails && <RenderContent content={acf.productDetails}/>}
              {acf.productPrice && acf.productPrice}
              <AddToQuote productId={wordpress_id}/>
            </div>
            {categories && categories.length ? (
              <div className="taxonomy">
                <h4>Categories</h4>
                <ul className="taglist">
                  {categories.map(category => (
                    <li key={`${category.slug}cat`}>
                      {category.slug &&
                        <GatsbyLink to={`/products/category/${category.slug}/`}>
                          {category.name}
                        </GatsbyLink>
                      }
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

const ProductPost = ( props ) => {
  const { data, location } = props;
  //console.log('props:',props)
  const { wordpressPost: post, wordpressWpSettings } = data;
  if (!post) return null;
  const { title, content, featured_image_url, yoast, categories, date, author, wordpress_id } = post;
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
      />
      <ProductPostTemplate
        location={location}
        post={post}
        blogSlug="products"
      />
    </Layout>
  )
}

export default Previewable(ProductPost, 'products');

export const pageQuery = graphql`
  fragment ProductFields on wordpress__wp_products {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProductPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    wordpressPost: wordpressWpProducts(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_image_url {
        source_url
      }
      acf {
        productPrice
        productDetails
      }
      categories {
        name
        slug
      }
      author {
        name
        slug
        avatar_urls {
          wordpress_48
        }
      },
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
  }
`
