import React, { Component } from 'react';
import ls from 'local-storage'
import GatsbyLink from '../GatsbyLink'
import './Quote.scss'

export default class AddToQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: ls.get('quote') ? ls.get('quote') : [],
      loaded: false,
      error: null,
      hasSubmitted: false,
      product: {
        productId: this.props.productId,
        note: '',
        qty: 1
      }
    }
  }
  componentDidMount() {
  }

  handleSubmit = ( e ) => {
    e.preventDefault()
    this.setState({ loading: true });
    const productArray = this.state.product
    const newArray = this.state.quote.concat(productArray)
    ls.set('quote', newArray)
    this.setState({ loading: false, submitted: true, quote: newArray, hasSubmitted: true });
  }

  //Form change
  handleChange(e) {
     let product = this.state.product
     product[e.target.name] = e.target.value
     this.setState({product})
  }

  render() {
    const { hasSubmitted, product } = this.state
    return (
      <div className="product-quote-action">
        {hasSubmitted &&
          <div>
            <p>This product has been added to the quote. <GatsbyLink to="/request-a-quote/">View quote</GatsbyLink></p>
          </div>
        }
        {!hasSubmitted &&
        <form onSubmit={event => {this.handleSubmit(event)}}>
          <div className="form-inner">
            <div className={`${product.note ? ' active' : ''}`}>
              <label htmlFor="note">Add a note</label>
              <textarea name="note" id="note" placeholder="Add a note" onChange={e => this.handleChange(e)} value={product.note} />
            </div>
            <div className={`${product.qty ? ' active' : ''}`}>
              <label htmlFor="qty">Qty</label>
              <input type="number" name="qty" id="qty" placeholder="Qty" onChange={e => this.handleChange(e)} value={product.qty} />
            </div>
            <div><button aria-label="Add to quote" type="submit">Add to quote</button></div>
          </div>
        </form>
        }
      </div>
    )
  }
}
